import { gql } from "@apollo/client";
import FormSection from "controls/FormSection";
import Message from "controls/Message";
import MultiValuesField from "controls/MultiValuesField";
import NumberInput from "inputs/NumberInput";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput, { IpAdddressInput } from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const VenueServersFormFragment = gql`
  fragment VenueServersFormFragment on VenueServer {
    id
    state
    details
    machineId
    venue {
      id
    }
    locallyAccessible
    uploaderRateLimitKbit
    internalNetwork
    internalNetworkIpAddress
  }
`;

export default function VenueServersForm({ venueServers }) {
  return (
    <>
      <MultiValuesField
        defaultValues={venueServers?.map((server) => server.state)}
        label="State"
        name="state"
        required
        input={
          <SelectInput
            options={[
              {
                text: "Unused",
                value: "unused",
                description: "No hardware has been linked with this venue server record",
              },
              {
                text: "Provisioned",
                value: "provisioned",
                description: "Hardware configured, but not yet installed in a venue.",
              },
              {
                text: "Active",
                value: "active",
                description: "Currently installed at a venue.",
              },
              {
                text: "Inactive",
                value: "inactive",
                description: "Installed at a venue, but not being used.",
              },
              {
                text: "Decommissioned",
                value: "decommissioned",
                description: "No longer in use.",
              },
            ]}
          />
        }
      />
      <MultiValuesField
        name="machineId"
        label="Machine ID"
        defaultValues={venueServers?.map((server) => server.machineId)}
        input={<TextInput />}
        helperText={
          <>
            Unique identifier for the server populated during Linux installation. Can be found out by running:
            <br />
            <code>cat /etc/machine-id</code>
          </>
        }
      />
      <MultiValuesField
        defaultValues={venueServers?.map((server) => server.details)}
        label="Details"
        name="details"
        input={<TextInput multiline minRows={5} />}
        helperText={<>Record transfer history of this server hardware.</>}
      />
      <FormSection header="Venue">
        <MultiValuesField
          name="venue"
          label="Venue"
          defaultValues={venueServers?.map((server) => server.venue)}
          input={<ReadminObjectInput optionsTypename="Venue" />}
          helperText="Leave blank until the server is assigned to a venue."
        />
        <MultiValuesField
          name="locallyAccessible"
          label="Locally Accessible"
          defaultValues={venueServers?.map((server) => server.locallyAccessible)}
          input={<SwitchInput />}
          helperText="Tick if we expect the iPad to be able to make a direct connection over the wireless network to this server. Improves video reliability."
        />
      </FormSection>
      <FormSection header="Uploader">
        <MultiValuesField
          name="uploaderRateLimitKbit"
          label="Upload Rate Limit"
          defaultValues={venueServers?.map((server) => server.uploaderRateLimitKbit)}
          input={<NumberInput unit="kbits/s" />}
          helperText="Set after running a speed test at the venue. Use ~80% of the maximum available upload bandwidth. 1000 kbit = 1 Mbit."
        />
      </FormSection>
      <FormSection header="Internal Network">
        <Message
          content={
            <>
              This defines the network that the cameras use, and which the venue server also has an address on.
              <br />
              10.9.9.0/24 is used in almost all venues, with the venue server occupying 10.9.9.50.
              <br />
              The cameras are given static IP addresses in the range 10.9.9.1 to 10.9.9.40. If there are other Glory
              League devices, such as switches, they are usually given an address such as 10.9.9.60.
            </>
          }
        />
        <MultiValuesField
          name="internalNetwork"
          label="Internal Network"
          defaultValues={venueServers?.map((server) => server.internalNetwork)}
          input={<IpAdddressInput />}
        />
        <MultiValuesField
          name="internalNetworkIpAddress"
          label="Internal Network IP Address"
          defaultValues={venueServers?.map((server) => server.internalNetworkIpAddress)}
          input={<IpAdddressInput />}
        />
      </FormSection>
    </>
  );
}
